<template>
  <b-modal
    id="team-invite-player-modal"
    :title="title"
    no-close-on-backdrop
    size="lg"
  >
    <template #modal-footer="{ cancel }">

      <b-row
        v-if="showErrorNotifications || showSuccessNotifications"
        class="flex-grow-1"
      >
        <b-col>
          <dismissible-message-box
            :show="showErrorNotifications"
            :variant="MessageType.ERROR"
            class="p-1"
          >
            <feather-icon
              class="mr-50"
              icon="InfoIcon"
            />
            {{ errors[0] }}
          </dismissible-message-box>
          <dismissible-message-box
            v-if="showSuccessNotifications"
            :show="showSuccessNotifications"
            :variant="MessageType.SUCCESS"
            class="p-1"
          >
            Saved successfully!
          </dismissible-message-box>
        </b-col>
      </b-row>
      <b-row class="flex-grow-1 flex-row-reverse">
        <b-col class="d-flex justify-content-end">

          <b-button
            variant="outline-primary"
            @click="cancel"
          >
            Close
          </b-button>
        </b-col>
      </b-row>
    </template>

    <b-row class="text-center">
      <b-col>
        <p>
          Before accepting invite we suggest checking players profile
        </p>
      </b-col>
    </b-row>

    <b-row class="flex-column align-items-center">
      <b-avatar
        :src="player?.avatar_image"
        :variant="`light-primary`"
        class="mb-2"
        size="104px"
      />
      <h4 class="mb-2">
        {{ player?.name }}
      </h4>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        class="mb-2"
        variant="primary"
        @click="openPlayerProfile"
      >
        View player profile
      </b-button>

      <b-row>
        <div>
          <spinning-button
            :is-loading="isLoadingAccept"
            class="mr-2"
            type="submit"
            variant="success"
            @click.native="acceptInvitation"
          >
            Accept
          </spinning-button>

          <spinning-button
            :is-loading="isLoadingDecline"
            type="submit"
            variant="danger"
            @click.native="declineInvitation"
          >
            Decline
          </spinning-button>
        </div>
      </b-row>
    </b-row></b-modal>
</template>

<script>
import {
  BAvatar, BButton, BCol, BModal, BRow, VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import DismissibleMessageBox from '@/components/DismissibleMessageBox.vue'
import MessageType from '@/constants/MessageType'
import SpinningButton from '@/components/SpinningButton.vue'

export default {
  components: {
    SpinningButton,
    DismissibleMessageBox,
    BModal,
    BButton,
    BAvatar,
    BCol,
    BRow,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    player: {
      type: Object,
      default: null,
    },
    requestId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      MessageType,
      isLoadingAccept: false,
      isLoadingDecline: false,
      errors: [],
      showErrorNotifications: false,
      showSuccessNotifications: false,
    }
  },
  computed: {
    title() {
      return `${this.player?.name} would like to join your team`
    },
  },
  methods: {
    openPlayerProfile() {
      const route = this.$router.resolve({
        name: 'player-profile',
        params: { id: this.player.id },
      })

      window.open(route.href, '_blank')
    },

    async saveResponse(accepted) {
      this.errors = []
      this.showErrorNotifications = false
      this.showSuccessNotifications = false

      if (accepted) {
        this.isLoadingAccept = true
      } else {
        this.isLoadingDecline = true
      }

      const { success } = await this.$api.team.respondToPlayerRequest(this.requestId, accepted)

      this.isLoadingAccept = false
      this.isLoadingDecline = false

      if (!success) {
        this.errors.push(
          'There was a problem saving the invitation request. Please try again or contact our support.',
        )
      } else {
        this.showSuccessNotifications = true

        setTimeout(() => {
          this.$emit('teamInvitationStatusChanged')
          this.$bvModal.hide('team-invite-player-modal')
        }, 3000)
      }
    },
    async acceptInvitation() {
      await this.saveResponse(true)
    },
    async declineInvitation() {
      await this.saveResponse(false)
    },
  },
}
</script>

<style lang="scss">
.modal-xl {
  margin-left: 17%;
  margin-right: 17%;
}

.modal .modal-header .close {
  transform: translate(-4px, 5px);

  &:hover,
  &:focus,
  &:active {
    transform: translate(-7px, 6px);
  }
}
</style>
